.modal {
    position: fixed;
    min-height:100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    width: 325px;
    background-color: rgb(255,255,255);
    border-radius: 3px;
}

.modal-header, .modal-footer {
    padding: 20px;
}

.modal-footer, .modal-submit {
    width:85%;
    display: inline-block;
    border-top: 1px solid rgb(238,238,238);
}

.modal-submit {
    padding-top: 10px;
}

.modal-title {
    margin: 0;
    font-weight: 700;
}

.modal-body {
    padding: 10px;
    border-top: 1px solid rgb(238,238,238);
}

.modal-form-content { 
    padding: 10px; 
    width: 93%; 
    display: flex;
    justify-content: space-between;
}

.modal-form-content-label { 
    display: inline-block; 
}