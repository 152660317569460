.dropdown-menu {
    width: 183px;
    padding-left: 15px;
    margin-left: -15px; 
    position: absolute;
    top: 40px;
    list-style: none;
    text-align: start;
}

.dropdown-menu li {
    background: rgb(60,180,124);
    cursor: pointer;
}

.dropdown-menu li:hover {
    background: rgb(14, 13, 13);
}

.dropdown-menu.clicked {
    display: none;
}

.dropdown-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
    padding: 7px;
}