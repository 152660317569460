* {
  font-family: "Open Sans", sans-serif !important;
}

#root {
  width: 100%;
}

body {
  /* background-position: 100% 100%;
  background-size: cover;
  background-image:url(movies\ library\ wallpaper\ rotated.jpg);
  background-attachment: fixed; */
  padding-top: 100px;
  background-color: #d3d3d3;
  /* max-width: 80%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  width: 80%;
  margin: 0 auto;
}

.margin-div {
  margin: 0 10px;
  display: inline-block;
}

.AppAuth {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 100;
}

h1,
h2 {
  font-weight: 900;
  /* color:rgb(255, 254, 254) */
}

.msd__auth-wrapper, 
/* #Sub-Component-Capture,
#Sub-Component-CheckoutConsents,
#Sub-Component-Purchase,
#Sub-Component-Subscriptions,
#Sub-Component-SubscriptionSwitches,
#Sub-Component-PaymentInfo,
#Sub-Component-TransactionList,
#Sub-Component-UpdateProfile,
#Sub-Component-Checkout,
#Sub-Component-Register,
#Sub-Component-Login,
#Sub-Component-PasswordReset, */
.sc-bvFjSx.ffvmha,
.sc-emDsmM.ivyHLS, 
#Sub-Component-PlanDetails,
.sc-ZOtfp.iLUgkP,
.msd__subscriptions__wrapper, 
.sc-oXPCX.jVsMdX, 
.sc-hlGDCY.kWfCkL.msd__subscriptions__wrapper,
.sc-iidyiZ.kjqvRY.msd__subscriptions__wrapper,
.sc-iidyiZ.kjqvRY.msd__subscription-switches__wrapper,
.sc-dYPfWK.kHDzzJ.msd__subscription-switches__wrapper,
.sc-fyrocj.jeDJpp,
.sc-iWVNaa.dZMrns,
.sc-dcgwPl.rNPBV.msd__payment-details__wrapper,
.sc-itWPBs.gRuIzN.msd__payment-details__wrapper,
.sc-eJRTFu.fmPSWQ.msd__transactions-list__wrapper,
.sc-fGoOlv.jlyIJp.msd__transactions-list__wrapper,
.sc-bzPmhk.dLXTyg.msd__user-profile__wrapper,
.sc-kHdrYz.fZlogU.msd__user-profile__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto;
  /* width: 80%; */
  height: 70%;
  /* border-radius: 10px; */
  overflow: hidden;
  /* box-shadow: 5px 10px 18px #000000; */
}

.sc-fyrocj.jeDJpp {
  /* width: 70%; This does not work - I have to go to <style> - do not know even where this is */
  padding: 22px;
}

.sc-iwjdpV.hTowqV {
  padding-top: 25px;
}

.msd__consents {
  display: flex;
  justify-content: flex-start;
  margin: 20px auto;
  width: 70%;
  height: 70%;
  overflow: hidden;
}

.hhwIgk,
.sc-cjbZfG.eFFKCr.msd__transaction__subtitle {
  text-align: left;
}

.erxXDR.msd__consents {
  border-radius: 10px;
}

.main-menu {
  background-image: linear-gradient(80deg, #2f2a93 0%, #df1d55 100%);
}

.menu,
.menu-link {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: transparent;
  padding: 12px 10px;
  margin: -4px 0px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: rgb(60, 180, 124);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.button {
  background-color: rgb(51, 185, 73);
  border: none;
  color: rgb(255, 255, 255);
  padding: 3px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 2px;
  font: inherit;
  margin-bottom: -5px;
}

.button-red {
  background-color: rgb(168, 0, 0);
  border: none;
  color: rgb(255, 255, 255);
  padding: 3px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 2px;
  font: inherit;
}

.button-orange {
  background-color: rgb(245, 137, 43);
  border: none;
  color: rgb(255, 255, 255);
  padding: 3px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 2px;
  font: inherit;
}

.grecaptcha-badge {
  visibility: hidden;
}

.button-adjustment {
  margin-top: -4px;
}

.button:hover,
.button-red:hover {
  background-color: rgb(60, 180, 124);
}

.button:active,
.button-red:active {
  background-color: rgb(62, 142, 65);
  transform: translateY(2px);
}

.modal-form-content input:active,
.modal-form-content input,
.modal-form-content select:active,
.modal-form-content select {
  border-style: inset;
  border: 1px solid rgba(139, 139, 139, 0.78);
  border-radius: 1px;
}

.note {
  background-color: rgb(255, 221, 163);
  border: solid 1px rgb(138, 57, 2);
  border-radius: 2px;
  line-height: 20px;
  overflow: hidden;
  padding: 1px;
  width: 166px;
  font-size: 14px;
}

.white-text {
  color: rgb(255, 255, 255);
}

.msd__account-wrapper {
  margin-top: -32px;
  margin-bottom: -32px;
}

.sc-kBzgEd.jIUhkE.msd__account-sidebar {
  padding: 110px 34px;
}

#UpperComponentMyAccount {
  margin-top: 60px;
}

@supports (-webkit-appearance: -apple-pay-button) {
  .adyen-checkout__applepay__button--white {
    -apple-pay-button-style: white !important;
  }
}

/* QUESTIONS */

/* class="sc-emDsmM ivyHLS" - how can I make space (margin-botton) below this div? (this is in the OnePageMS when user is not logged in */
